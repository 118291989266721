html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.snackbar-error {
  background-color: #f44336;
  color: white;
}
.snackbar-success {
  background-color: #4caf50;
  color: white;
}
